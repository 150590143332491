import { vappContainer } from '@/application';
import { EventTrackerPageService } from '@pages/EventTrackerPage/services';

const MODULE_NAME = 'EventTrackerPageService.machines.states.getAnalyticsPage';

const getSrv = () => {
  return vappContainer.get<EventTrackerPageService>(
    EventTrackerPageService.displayName
  );
};

export const getAnalytics = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'getAnalytics';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const _getArgs = () => {
        let _args: any = {};
        if (event?.type === 'START') {
          _args = context['GET_PAGE_CONTENT'];
        }
        if (event?.type === 'TRIGGER_REFRESH') {
          _args = {
            offset: event?.offset,
            size: event?.size,
            year: event?.year,
            month: event?.month,
          };
        }
        return _args;
      };
      const content = await srv.agent?.getEventTrackerPageProps(_getArgs());

      if (content) {
        return resolve({
          key: 'RENDER_ANALYTICS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'RENDER_ANALYTICS_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'RENDER_ANALYTICS_ERROR',
        value: err?.message,
      });
    }
  });
};
