export const routes = {
  Home: {
    path: '/',
    id: 'Home',
  },
  FAQPage: {
    path: '/faq',
    id: 'FAQPage',
  },
  Register: {
    path: '/register',
    id: 'Register',
  },
  SignIn: {
    path: '/sign-in',
    id: 'SignIn',
  },
  SignUp: {
    path: '/sign-up',
    id: 'SignUp',
  },
  Terms: {
    path: '/terms',
    id: 'Terms',
  },
  Privacy: {
    path: '/privacy',
    id: 'Privacy',
  },
  ForgotPassword: {
    path: '/forgot-password',
    id: 'ForgotPassword',
  },
  ResetPassword: {
    path: '/reset-password',
    id: 'ResetPassword',
  },
  Lock: {
    path: '/lock',
    id: 'Lock',
  },
  AccountSelection: {
    path: '/account',
    id: 'AccountSelection',
  },
  NotFound: {
    path: '/404',
    id: 'NotFound',
  },
  ServerError: {
    path: '/500',
    id: 'ServerError',
  },
  Profile: {
    path: '/profile',
    id: 'Profile',
  },
  DashboardOverview: {
    path: '/dashboard/overview',
    id: 'DashboardOverview',
  },
  Users: {
    path: '/users',
    id: 'Users',
  },
  Placeholder: {
    path: '/placeholder',
    id: 'Placeholder',
  },
  OrganizationProfile: {
    path: '/profile/organization-profile',
    id: 'OrganizationProfile',
  },
  MyProfile: {
    path: '/profile/my-profile',
    id: 'MyProfile',
  },
  UsersList: {
    path: '/users',
    id: 'UsersList',
  },
  ProvisionalPolicies: {
    path: '/provisional',
    id: 'ProvisionalPolicies',
  },
  ProvisionalPolicyDetail: {
    path: '/provisional/policy-detail',
    id: 'ProvisionalPolicyDetail',
  },
  ActualPolicyDetails: {
    path: '/actual/policy-detail',
    id: 'ActualPolicyDetails',
  },
  ArchivedPolicyDetail: {
    path: '/archive/policy-detail',
    id: 'ArchivedPolicyDetail',
  },
  EventPolicyDetails: {
    path: '/events/policy-detail',
    id: 'EventPolicyDetails',
  },
  ActualPolicies: {
    path: '/actual',
    id: 'ActualPolicies',
  },
  ArchivedPolicies: {
    path: '/archived',
    id: 'ArchivedPolicies',
  },
  EventPolicies: {
    path: '/events/event-detail',
    id: 'EventPolicies',
  },
  Holders: {
    path: '/holders',
    id: 'Holders',
  },
  HoldersDetail: {
    path: '/holders/detail',
    id: 'HoldersDetail',
  },
  PolicyDetail: {
    path: '/holders/policy-detail',
    id: 'PolicyDetail',
  },
  CreditUnion: {
    path: '/credit-unions',
    id: 'CreditUnion',
  },
  UserListDetail: {
    path: '/users/:id',
    id: 'UserListDetail',
  },
  CreditUnions: {
    path: '/credit-unions',
    id: 'CreditUnions',
  },
  CreditUnionUsers: {
    path: '/credit-unions/users',
    id: 'CreditUnionUsers',
  },
  Products: {
    path: '/flexible-hurricane-protection',
    id: 'Products',
  },
  FHPDetail: {
    path: '/flexible-hurricane-protection/detail',
    id: 'FHPDetail',
  },
  FHPAnalytics: {
    path: '/flexible-hurricane-protection/analytics',
    id: 'FHPAnalytics',
  },
  SelectProduct: {
    path: '/select-product',
    id: 'SelectProduct',
  },
  Activity: {
    path: '/activity',
    id: 'Activity',
  },
  WeatherAnalytics: {
    path: '/actual/policy-detail/weather-analytics/:id',
    id: 'WeatherAnalytics',
  },
  AnalyticsAPIDetail: {
    path: '/actual/policy-detail/weather-analytics/api-detail/:id',
    id: 'AnalyticsAPIDetail',
  },
  Events: {
    path: '/events',
    id: 'Events',
  },
  AllEvent: {
    path: '/allevent',
    id: 'AllEvent',
  },
  DeathEvent: {
    path: '/deathevent',
    id: 'DeathEvent'
  }
};
