import { vappContainer } from '@/application';
import { ActualDetailPageService } from '@pages/ActualDetailPage/services';

const MODULE_NAME = 'ActualDetailPage.machines.states.getBlockchainProofPage';

const getSrv = () => {
  return vappContainer.get<ActualDetailPageService>(
    ActualDetailPageService.displayName
  );
};

export const getBlockchainProof = async (context: any, event: any) => {
  const currentState = 'GET_BLOCK_CHAIN_PROOF';
  const method = 'getBlockchainProof';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.agent?.getBlockchainTransaction(event?.payload);

      if (content) {
        return resolve({
          key: 'GET_BLOCK_CHAIN_PROOF_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'GET_BLOCK_CHAIN_PROOF_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'GET_BLOCK_CHAIN_PROOF_ERROR',
        value: err?.message,
      });
    }
  });
};
