//@ts-nocheck
import React from 'react';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  BackspaceIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

import { toHeaderCase } from 'js-convert-case';
import { ToggleAction } from './ToggleAction';

import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import * as S from './styles';
const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1);

const getFirstLetterOfEachWord = (text) => text?.match(/\b\w/g).join('');

export const DomainTableRow = (props) => {
  const {
    id,
    rowData,
    vid,
    verified,
    status,
    policyNo,
    group,
    image,
    name,
    email,
    dateCreated,
    isSelected,
    selectUser,
    deleteUsers,
    coverage,
    premium,
    payout,
    policyOption,
    onViewDetailClick,
    onRevokeClick,
    onRowClick,
    viewTitle,
  } = props;
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
  const statusVariant =
    status === 'PAID' ? 'success' : status === 'UNPAID' ? 'danger' : 'primary';
  const onToggle = (key: string) => {
    console.log(`toggle in row`, key, email);
    onTogglePress && onTogglePress(key, email);
  };
  const onUserChecked = (event: any, id: string) => {
    selectUser && selectUser(id);
    event.stopPropagation();
  };

  return (
    <tr className="border-bottom" onClick={() => onRowClick(rowData)}>
      <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${id}`}
            checked={isSelected}
            onClick={(e) => onUserChecked(e, id)}
          />
          <FormCheck.Label htmlFor={`user-${id}`} />
        </FormCheck>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          {image ? (
            <Image src={image} className="avatar rounded-circle me-3" />
          ) : (
            <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
              <span>{getFirstLetterOfEachWord(name)}</span>
            </div>
          )}
          <div className="d-block">
            <span className="fw-bold">{name}</span>
            <div className="small text-gray">{email}</div>
          </div>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{policyNo}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{coverage}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{payout}</span>
        </Card.Link>
      </td>
      {/* <td>
        <Card.Link className="d-flex align-items-center ">
          <span className={`fw-normal text-${statusVariant} me-1`}>{status}</span>
        </Card.Link>
      </td> */}
      <td>
        <span className={`fw-normal text-white`}>
          <button
            className={`btn btn-${statusVariant} rounded-pill btn-sm text-white`}
          >
            {capitalizeFirstLetter(status)}
          </button>
        </span>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{toHeaderCase(policyOption ?? '')}</span>
        </Card.Link>
      </td>

      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{dateCreated}</span>
        </Card.Link>
      </td>
      {/* <td>
        <span className="fw-normal d-flex align-items-center">
          <VerifiedIcon
            className={`icon icon-xxs text-${statusVariant} me-1`}
          />
          Email
        </span>
      </td> */}
      {/* <td>
        <span className={`fw-normal text-${statusVariant}`}>
          {capitalizeFirstLetter(status)}
        </span>
      </td> */}
      <td>
        <ToggleAction status={status} onTogglePress={onToggle} />
        {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="m-0">Delete</Tooltip>}
        >
          <Card.Link
            className="ms-2"
            onClick={() => deleteUsers && deleteUsers(email)}
          >
            <XCircleIcon className="icon icon-xs text-danger" />
          </Card.Link>
        </OverlayTrigger> */}
      </td>
    </tr>
  );
};

DomainTableRow.defaultProps = {
  viewTitle: 'View Certificate',
};
