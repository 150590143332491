import React from 'react';
import { Accordion, Image } from 'react-bootstrap';
import * as S from './styles';

export interface IHeaderCompProps {
  rightBlockContent: {
    title?: string;
    description?: string;
    image?: string;
  }[];
}

export const RightContainer = (props: IHeaderCompProps) => {
  const { rightBlockContent } = props;

  const _renderImageIf = (image: string) => {
    if (image) {
      return <Image src={image} />;
    }
  };
  const _renderDescriptionIf = (description: string) => {
    if (
      description &&
      !Array.isArray(description) &&
      typeof (description === 'string')
    ) {
      return <p>{description}</p>;
    }
    if (Array.isArray(description)) {
      return (
        <>
          {description?.map((str, index) => {
            return <p key={`desc-${index}`}>{str}</p>;
          })}
        </>
      );
    }
  };
  const _getAccordionData = () => {
    let _tempData =
      Array.isArray(rightBlockContent) &&
      rightBlockContent?.map((item: any, index: number) => {
        return (
          <Accordion.Item eventKey={`acc-${index}`} key={`acc-${index}`}>
            <Accordion.Header>
              <S.AccHeader>{item?.title}</S.AccHeader>
            </Accordion.Header>
            <Accordion.Body>
              {_renderDescriptionIf(item?.description)}
              {_renderImageIf(item?.image)}
            </Accordion.Body>
          </Accordion.Item>
        );
      });
    return <Accordion defaultActiveKey="acc-0">{_tempData}</Accordion>;
  };

  return (
    <S.ColContainer md={12} xs={12}>
      {_getAccordionData()}
    </S.ColContainer>
  );
};
RightContainer.defaultProps = {
  rightBlockContent: [
    {
      title: 'Which country should I use?',
      description:
        'You can use any country from the listed ones,in Which you have an insurable interest.This can be a place you live in, work at, or own',
    },
    {
      title: 'How is my Country used?',
      description:
        'We use your country to determine the payout after an event.The greatest the impact of the hurricane,the more relief for your wallet with larger payouts',
    },
  ],
};
